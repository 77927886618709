import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
 
$(document).ready(() => {
  /**
   * Home slider
   */
  const sliderElement = document.querySelector('.home-slider');
  if (sliderElement) {
    const slider = new Swiper(sliderElement, {
      spaceBetween: 20,
      loop: true,
      modules: [Navigation, Pagination, Autoplay],
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }
});